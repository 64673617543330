import axios from 'axios'
import Cookies from 'js-cookie'
import { eventEmitter } from '../../common'

/**
 * Class representing an ESputnik Tracker.
 */
class ESputnikTracker {

  loadScript () {
    let s = document.createElement('script')
    s.async = true, s.src = 'https://statics.esputnik.com/scripts/5F4452D270B0476698F17A71F3E5DD20.js'
    s.onload = () => {
      eventEmitter.emit('EsputnikLoaded')
      window.sessionStorage.setItem('EsputnikLoaded', 'yes')
    }
    let r = document.scripts[0]
    r.parentNode.insertBefore(s, r)
    let f = function () {
      f.c(arguments)
    }
    f.q = []
    f.c = function () {
      f.q.push(arguments)
    }
    window['eS'] = window['eS'] || f

    eS('init', { TRACKING: false, RECOMS: true })
  }

  /**
   * Событие просмотра страницы
   * @constructor
   */
  PageView () {
    eS('sendEvent', 'PageView', {
      'PageView': {}
    })
  }

  /**
   * Отправляем запрос об изменении корзины в ESputnik
   */
  sendBasketToESputnik () {
    //Формируем GUID и записываем данные в куки
    var uniqueId = this.generateUniqueId()
    Cookies.set('BITRIX_SM_BASKET_STATE_GUID', uniqueId, { expires: 365 })
    axios.post(`/local/api/eSputnik/basketItemsList.php`, `guid=${uniqueId}`)
      .then(response => {
        //Отправка в ESputnik
        eS('sendEvent', 'StatusCart', {
          'StatusCart': response.data,
          'GUID': uniqueId
        })
      })
      .catch(error => {
        // Обработка ошибок при отправке запроса
        console.error(error)
      })
  }

  /**
   * Генерация уникального GUID
   * @returns {string}
   */
  generateUniqueId () {
    const formatString = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    return formatString.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  /**
   * Событие отправки параметров пользователя
   * @param data
   */
  sendCustomerData (data) {
    eS('sendEvent', 'CustomerData', {
      'CustomerData': data
    })
  }

  SearchRequest (query, has_items) {
    try {
      eS('sendEvent', 'SearchRequest', {
        'SearchRequest': {
          'search': query,
          'isFound': has_items
        }
      })
    } catch (e) {
      console.error('Error eSputnik send event "SearchRequest"')
    }
  }
}

export default ESputnikTracker