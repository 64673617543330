'use strict';

class Template {
    static init() {
        // Search

        if (window.innerWidth > 1023) {
            $('.search__input').on('click', function () {
                $('.search').addClass('open');
                $('.search__input').focus();
            });
            $(document).on('click touchstart', function (e) {
                if (!$(e.target).closest('.search__button, .search').length) {
                    $('.search').removeClass('open');
                }
                e.stopPropagation();
            });
        }

        // Phones hover

        $('.phones-list__current').on('mouseover', function hoverPhones() {
            $(this).addClass('active');
            $(this).siblings('.phones-list__dropdown').addClass('open');
        });
        $('.phones__box').on('mouseleave', function () {
            $('.phones-list__current').removeClass('active');
            $('.phones-list__dropdown').removeClass('open');
        });

        // Language select

        $('.language__current').on('mouseover', function hoverPhones() {
            $(this).addClass('active');
            $(this).siblings('.language__dropdown').addClass('open');
        });
        $('.language__box').on('mouseleave', function () {
            $('.language__current').removeClass('active');
            $('.language__dropdown').removeClass('open');
        });

        //Login form
        $('.forgot-password--link').on('click', function (e) {
            e.preventDefault();
            $('.login-form').hide();
            $('.recovery-password').show();
        });
        $('.remembered-link').on('click', function (e) {
            e.preventDefault();
            $('.recovery-password').hide();
            $('.login-form').show();
        });

        let mobileHeader = () => {
            if (window.innerWidth < 1023) {
                $('.header__bottom .search').css({'display': 'flex'});
            }
        };
        mobileHeader();
        $(window).on('load resize', mobileHeader);

        let updatePageWrapperHeight = () => {
            let pageWrapper = document.querySelector('.wrapper');
            let footerSelector = document.querySelector('.footer');
            if (pageWrapper && footerSelector) {
                let footerSelectorHeight = footerSelector.offsetHeight + 'px';
                pageWrapper.style.paddingBottom = footerSelectorHeight;
            }
        };
        /*updatePageWrapperHeight();
        $(window).on('resize', updatePageWrapperHeight);*/
        
        //Popup

        let htmlBlockHollder = document.querySelector('html');
        let popupLink = document.querySelectorAll('.popup-open[data-for]');

        let popupOverlayActive = function popupOverlayActive() {
            let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            htmlBlockHollder.style.marginRight = scrollbarWidth;
            htmlBlockHollder.style.overflow = 'hidden';
        };
        let popupOverlayDestroy = function popupOverlayDestroy() {
            htmlBlockHollder.removeAttribute('style');
        };
        let bodyBlock = document.querySelector('body');
        let iosRemoveClass = function iosRemoveClass() {
            bodyBlock.classList.remove('iosBugFixCaret');
        };

        let popupOpen = function popupOpen(link) {
            let popupElement = document.querySelector('.' + link.dataset.for);
            if (popupElement) {
                link.addEventListener('click', function (event) {
                    event.preventDefault();
                    popupElement.classList.toggle('active');
                    popupOverlayActive();
                });
            }
        };
        Array.from(popupLink).forEach(popupOpen);

        let popupClose = () => {
            let close = document.querySelectorAll('.popup__close');
            let popupInner = document.querySelectorAll('.popup');
            let popupOverlays = document.querySelectorAll('.popup__overlay');
            close.forEach(function (closeElement) {
                closeElement.addEventListener('click', function () {
                    popupInner.forEach(function (popupInnerElement) {
                        popupInnerElement.classList.remove('active');
                        popupOverlayDestroy();
                        iosRemoveClass();
                    });
                });
            });
            popupOverlays.forEach(function (popupOverlaysElement) {
                popupOverlaysElement.addEventListener('click', function () {
                    popupInner.forEach(function (popupInnerElement) {
                        popupInnerElement.classList.remove('active');
                        popupOverlayDestroy();
                        iosRemoveClass();
                    });
                });
            });
        };
        popupClose();

        let ua = navigator.userAgent;
        let iOS = /iPad|iPhone|iPod/.test(ua);
        let iOS11 = /OS 11/.test(ua);
        let popupBtn = document.querySelectorAll('.popup-open');

        if (iOS && iOS11) {
            popupBtn.forEach(function (popupBtnSingle) {
                popupBtnSingle.addEventListener('click', function () {
                    bodyBlock.classList.add('iosBugFixCaret');
                });
            });
        }

    }
}

export {Template};