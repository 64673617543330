import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
// configure Swiper to use modules
Swiper.use([Autoplay, Pagination, Navigation]);

class EndBanner {
  constructor (wrapper) {
    this.wrapper = wrapper
  }

  init () {
    new Swiper(this.wrapper.querySelector('.swiper'), {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Navigation, Autoplay],
      effect: 'fade',
      navigation: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      }
    })
  }
}

export default EndBanner