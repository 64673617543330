'use strict'

import React from 'react'

import PotentialQueryList from '../potentialQueryList/PotentialQueryList'
import HistoryList from '../HistoryList/HistoryList'
import { CategoryList } from '../categoryList'
import ProductList from '../ProductList/ProductList'
import { TotalResult } from '../totalResult'

function Result (props) {

  return (
    <div className="title-search-result show">
      <div className="bx_searche">
        <div className="search-result-main-info">
          <div className="addition-items-holder">
            {
              props.potentialQueryList.length > 0 ?
                <PotentialQueryList
                  items={props.potentialQueryList}
                  onItemClick={props.onPotentialQueryClick}
                  setSelectedQuery={props.setSelectedQuery}
                /> :
                <></>
            }
            {
              props.historyList.length > 0 && props.historyToggle ?
                <div className="search-result-category-holder">
                  <HistoryList
                    items={props.historyList}
                    title={props.messages['CT_BST_HISTORY_LIST_TITLE']}
                    onItemClick={props.onQueryItemClick}
                    onItemDelete={props.onDeleteHistoryQueryClick}
                  />
                </div> :
                <></>
            }
            {
              props.categoryList.length > 0 ?
                <CategoryList
                  items={props.categoryList}
                  title={props.messages['CT_BST_CATEGORY_LIST_TITLE']}
                /> :
                <></>
            }
          </div>
          <hr className="line"/>
          <div className="main-items-holder">
            {
              props.productList.length > 0 ?
                <ProductList
                  items={props.productList}
                  title={props.messages['CT_BST_PRODUCT_LIST_TITLE']}
                /> :
                <></>
            }
            <div className="search-result-total-info">
              <TotalResult title={props.messages['CT_BST_TOTAL_RESULT_LINK']}
                           number={props.total}
                           link={props.totalLink}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result