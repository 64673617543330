import './ProductList.css'
import React from 'react'
import { CustomScroll } from 'react-custom-scroll'
import { eventEmitter } from '../../../common'

function ProductList (props) {
  let i = 0

  let items = (window.innerWidth <= 767) ? props.items.slice(0, 5) : props.items

  function onClick(event) {
    let target = event.target
    let href = target.getAttribute('href')
    let productId = target.dataset['productId']
    eventEmitter.emit('MSAddClickHistory', {productId: productId})
    eventEmitter.emit('MSClick', { productId: productId })
  }

  return (
    <div className={'item_block_holder'} style={{height: '480px'}}>
      <div className="total_result_title">
        <span>{props.title}</span>
      </div>
      <div className={'product-list'} style={{height: 'calc(100% - 30px)'}}>
        <CustomScroll heightRelativeToParent="100%" allowOuterScroll={window.innerWidth <= 767}>
        {
          items.map(item => {
            let style = { backgroundImage: 'url(' + item['PICTURE'] + ')' }
            return (
              <div className="bx_item_block" key={i++}>
                <div className="bx_img_element">
                  <a href={item.DETAIL_PAGE_URL} data-product-id={item.ID} onClick={onClick}>
                    <span className="bx_image" style={style}/>
                  </a>
                </div>
                <div className="bx_item_element">
                  <div className="info_product_holder">
                    <a href={item.DETAIL_PAGE_URL} data-product-id={item.ID} className="product_name" onClick={onClick}>
                      {item['NAME']}
                    </a>
                    <span className="code">{item['ARTICLE']}</span>
                  </div>
                  <div className="info_price_holder">
                    {(item.hasOwnProperty('OLD_PRICE') && item['OLD_PRICE'].length > 0)
                      && <div className="bx_old_price_ua">{item['OLD_PRICE']}</div>}
                    <div className="bx_price_ua">{item['PRICE']}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </CustomScroll>
      </div>
    </div>
  )
}

export default ProductList