'use strict'

import React from 'react'
import './HistoryList.css'

function HistoryList(props) {
  // Ограничиваем список истории максимум 3 записями
  const limitedItems = props.items.slice(0, 3);

  return (
    <div className="bx_item_block all_result query-history-list">
      <div className="total_result_title">
        <span>{props.title}</span>
      </div>
      <div className="total_result_categori-items history-items">
        {limitedItems.map((item, key) => {
          return (
            <div key={key} className="query-history-item" data-container="queryHistoryItem" data-value={item}>
              <span className="query-name" onClick={event => props.onItemClick(item)}>{item}</span>
              <span className="delete-query" onClick={event => props.onItemDelete(item)}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HistoryList