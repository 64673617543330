'use strict'

import './PotentialQueryList.css'
import React from 'react'

function PotentialQueryList (props) {
  const regex = /(<([^>]+)>)/ig;

  return (
    <div className="search-result-category-holder list-item">
      <div className="bx_item_block all_result">
        <div className="potential-query-list">
          {
            props.items.map((suggest, index) => {
              return (
                <div key={index}>
                  <span dangerouslySetInnerHTML={{__html: suggest}}
                        onClick={() => {
                          props.onItemClick(suggest.replace(regex, ''))
                          props.setSelectedQuery(suggest.replace(regex, ''));
                        }}/>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default PotentialQueryList