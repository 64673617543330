'use strict';

export class Currency {
	
	constructor() {
		this.currencyList = [];
		/** @type {{FORMAT_STRING: string, THOUSANDS_SEP: string, DECIMALS: number, HIDE_ZERO: string, DEC_POINT: string}} */
		this.defaultFormat = {
			'FORMAT_STRING': '#',
			'DEC_POINT': '.',
			'THOUSANDS_SEP': ' ',
			'DECIMALS': 2,
			'HIDE_ZERO': 'N'
		};
	}
	
	/**
	 *
	 * @param {[]} array
	 */
	set currencyList(array) {
		this._currencyList = (Array.isArray(array)) ? array : [];
	}
	
	/**
	 *
	 * @returns {[]|[]}
	 */
	get currencyList() {
		return this._currencyList;
	}
	
	/**
	 *
	 * @param {String} currency
	 * @param {Object} format
	 * @param replace
	 */
	setCurrencyFormat(currency, format, replace) {
		let index = this.getCurrencyIndex(currency),
			currencyFormat = Object.assign({}, this.defaultFormat),
			i;

		replace = !!replace;
		if (index > -1 && !replace) {
			return;
		}
		if (index === -1) {
			index = this.currencyList.length;
		}
		
		for (i in currencyFormat) {
			if (currencyFormat.hasOwnProperty(i) && typeof format[i] !== 'undefined') {
				currencyFormat[i] = format[i];
			}
		}
		this.currencyList[index] = {
			'currency': currency,
			'format': Object.assign({}, currencyFormat)
		};
	}
	
	/**
	 *
	 * @param {[]}currencies
	 * @param replace
	 */
	setCurrencies(currencies, replace) {
		let i;
		if (!!currencies && Array.isArray(currencies)) {
			for (i = 0; i < currencies.length; i++) {
				if (!!currencies[i].CURRENCY && !!currencies[i].FORMAT) {
					this.setCurrencyFormat(currencies[i].CURRENCY, currencies[i].FORMAT, replace);
				}
			}
		}
	}
	
	/**
	 *
	 * @param {String} currency
	 * @returns {Object|boolean}
	 */
	getCurrencyFormat(currency) {
		let index = this.getCurrencyIndex(currency);
		return (index > -1 ? this.currencyList[index].format : false);
	}
	
	/**
	 *
	 * @param {String} currency
	 * @returns {number}
	 */
	getCurrencyIndex(currency) {
		let i, index = -1;
		if (this.currencyList.length === 0) {
			return index;
		}
		for (i = 0; i < this.currencyList.length; i++) {
			if (this.currencyList[i].currency === currency) {
				index = i;
				break;
			}
		}
		return index;
	}
	
	/**
	 *
	 * @param {String} currency
	 */
	clearCurrency(currency) {
		let index = this.getCurrencyIndex(currency);
		if (index > -1)
			this.currencyList = this.currencyList.slice(0, index).concat(this.currencyList.slice(index + 1));
	}
	
	clean() {
		this.currencyList = [];
	}
	
	/**
	 *
	 * @param {Number} price
	 * @param {String} currency
	 * @param {boolean} useTemplate
	 * @returns {string}
	 */
	currencyFormat(price, currency, useTemplate) {
		let result = '',
			format;
		useTemplate = !!useTemplate;
		format = this.getCurrencyFormat(currency);
		if (!!format && typeof format === 'object') {
			format.CURRENT_DECIMALS = format.DECIMALS;
			if (format.HIDE_ZERO === 'Y' && price == parseInt(price, 10))
				format.CURRENT_DECIMALS = 0;
			
			result = this.number_format(price, format.CURRENT_DECIMALS, format.DEC_POINT, format.THOUSANDS_SEP);
			if (useTemplate)
				result = format.FORMAT_STRING.replace(/(^|[^&])#/, '$1' + result);
		}
		return result;
	}
	
	/**
	 *
	 * @param number
	 * @param decimals
	 * @param dec_point
	 * @param thousands_sep
	 * @returns {string}
	 */
	number_format(number, decimals, dec_point, thousands_sep) {
		let i, j, kw, kd, km, sign = '';
		decimals = Math.abs(decimals);
		if (isNaN(decimals) || decimals < 0) {
			decimals = 2;
		}
		dec_point = dec_point || ',';
		if (typeof thousands_sep === 'undefined')
			thousands_sep = '.';
		
		number = (+number || 0).toFixed(decimals);
		if (number < 0) {
			sign = '-';
			number = -number;
		}
		
		i = parseInt(number, 10) + '';
		j = (i.length > 3 ? i.length % 3 : 0);
		
		km = (j ? i.substr(0, j) + thousands_sep : '');
		kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
		kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, '0').slice(2) : '');
		
		return sign + km + kw + kd;
	}
}