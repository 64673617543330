'use strict';

export class SeoText {
	constructor() {
		this.text = document.getElementById('seo-text');
		this.more = document.getElementById('seo-more');
		this.init();
	}
	init() {
		let self = this;
		var text = this.text
		var more = this.more;
		
		
		if (text) {
			//text.style = 'max-height: ' + self.getHeight() + 'px';
			more.style = 'display : none;';
			
			/*if (text.scrollHeight > self.getHeight()) {
				more.style = 'display : table;';
			} else {
				more.style = 'display : none;';
			}*/
			
			
			more.onclick = function () {
				if (more.className == 'read-more') {
					text.style = 'max-height: ' + self.getHeight() + 'px';
				} else if(more.className == 'read-more open') {
					text.style = 'max-height: 9999px';
				}
				
			}
			
		}
	}
	
	getHeight() {
		let text = this.text;
		
		var height = 0;
		if (text && text.children.length > 0) {
			if (text.children[0] !== undefined) {
				var first = text.children[0].offsetHeight;
				var firstTop = parseInt(getComputedStyle(text.children[0], true).marginTop);
				var firstDown = parseInt(getComputedStyle(text.children[0], true).marginBottom);
			}
			if (text.children[1] !== undefined) {
				var second = text.children[1].offsetHeight;
				var secondTop = parseInt(getComputedStyle(text.children[1], true).marginTop);
				var secondDown = parseInt(getComputedStyle(text.children[1], true).marginBottom);
			}
			if (text.children[2] !== undefined) {
				var third = text.children[2].offsetHeight;
				var thirdTop = parseInt(getComputedStyle(text.children[2], true).marginTop);
				var thirdDown = parseInt(getComputedStyle(text.children[2], true).marginBottom);
			}
			
			if (firstDown > secondTop){
				first = first + firstDown;
			} else {
				second = second + secondTop;
			}
			
			if (secondDown > thirdTop) {
				second = second + secondDown;
			} else {
				third = third + thirdTop
			}
			height = first + second + third + firstTop;
		} else if (text){
			height = text.scrollHeight;
		}
		
		return height;
	}
}