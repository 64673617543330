'use strict';

class Application {
    constructor(params) {
        this._params = params;
    }

    getSiteId() {
        return (this._params.hasOwnProperty('siteId')) ? this._params['siteId'] : null;
    }

    getSessionId() {
        return (this._params.hasOwnProperty('sessionId')) ? this._params['sessionId'] : null;
    }

    getUserId() {
        return (this._params.hasOwnProperty('userId')) ? this._params['userId'] : null;
    }

    getFuserId() {
        return (this._params.hasOwnProperty('fuserId')) ? this._params['fuserId'] : null;
    }

    userIsAuth() {
        return (this._params.hasOwnProperty('userIsAuth')) ? this._params['userIsAuth'] : null;
    }

    getLanguageId() {
        return (this._params.hasOwnProperty('languageId')) ? this._params['languageId'] : null;
    }

    /**
     *
     * @returns {int[]}
     */
    userGroups() {
        return this._params.hasOwnProperty('userGroups') && Array.isArray(this._params['userGroups']) ? this._params['userGroups'] : [];
    }
}


export {Application};