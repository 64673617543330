import React from 'react'
import icons from '../../common/images/sprite.svg'

function Icon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg"
		     xmlnsXlink="http://www.w3.org/1999/xlink"
		     className={`${props.className || ''}`}
		     style={props.hasOwnProperty('style') ? props.style : {}}>
			<use xlinkHref={`${icons}#${props.name}`}/>
		</svg>
	)
}

export default Icon