'use strict';
import React from 'react';

export function CategoryList(props) {
    return (
        <div className="search-result-category-holder list-item">
            <div className="bx_item_block all_result">
                <div className="total_result_title">
                    <span>{props.title}</span>
                </div>
                <div className="total_result_categori-items">
                    {props.items.map((item, key) => {
                        return (
                            <div className="total_result_categori-item with-count" key={key}>
                                <a href={item['FILTER_PAGE_URL']} className="asdasda total_result_categori-link">{item['NAME']}</a>
                                <span className="count">({item['COUNT']})</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}