import './CatalogDropdownMenu.css'
import React, { useEffect, useState } from 'react'
import CatalogRootSection from './catalogRootSection/CatalogRootSection'
import Icon from '../icon/Icon'

function CatalogDropdownMenu (props) {

  const [state, setState] = useState({
    sections: props.sections || [],
    popular: props.popular || {},
    links: props.links || {},
    messages: props.messages || {}
  })

  const [overlay, setOverlay] = useState(null)
  const [rootSections, setRootSections] = useState([])
  const [opened, setOpened] = useState(false)
  const [clientWidth, setClientWidth] = useState(window.innerWidth)
  const [activeRootSectionId, setActiveRootSectionId] = useState(null)

  const getRootSections = (sections) => {
    if (!sections.length) return []

    return sections.reduce((rootSections, section) => {
      if (section.hasOwnProperty('depthLevel') && Number(section.depthLevel) === 1) {
        return [...rootSections, section]
      }
      return rootSections
    }, [])
  }

  const setBlockHeight = () => {
    var screenHeight = window.innerHeight; // Получаем высоту экрана
    var catalogMenu = document.querySelector('.catalog-menu-wrap--active'); // Находим блок с классом catalog-menu-wrap--active
    if (catalogMenu) {
      // Вычисляем высоту, которая равна высоте экрана минус отступы блока
      var blockHeight = screenHeight - 185; // 135px - отступ сверху, можно изменить в соответствии с вашими требованиями
      catalogMenu.style.height = blockHeight + 'px'; // Устанавливаем вычисленную высоту блока
    }
  }

  // client width
  useEffect(() => {
    window.addEventListener('resize', () => setClientWidth(window.innerWidth))
  }, [])

  // define dropdown overlay
  useEffect(() => {
    let found_overlay = document.querySelector('.bg-overlay')
    if (found_overlay instanceof HTMLElement) {
      found_overlay.addEventListener('click', event => {
        event.preventDefault()
        event.stopPropagation()
        setOpened(false)
      })
      setOverlay(found_overlay)
    }
  }, [])

  // update root sections list
  useEffect(() => {
    setRootSections(getRootSections(state.sections))
  }, [state.sections])

  useEffect(() => {
    if (opened) {
      // Вызываем функцию setBlockHeight() при изменении размера окна
      setBlockHeight();
      window.addEventListener('resize', setBlockHeight);
    }
  }, [opened])

  // set initial active root section
  useEffect(() => {
    if (activeRootSectionId !== null || !rootSections.length) return
    setActiveRootSectionId(rootSections[0]['itemId'])
  }, [rootSections])

  useEffect(() => {
    if (!(overlay instanceof HTMLElement)) return
    if (opened) {
      overlay.classList.add('active-bg')
    } else {
      overlay.classList.remove('active-bg')
    }
  }, [opened])

  function handleMouseOver (section_id) {
    setActiveRootSectionId(section_id)
  }

  function getMessage (code) {
    return state.messages.hasOwnProperty(code) ? state.messages[code] : 'undefined'
  }

  return (
    <>
      {
        clientWidth > 1023 ?
          <>
            <div className="catalog-opener__btn" onClick={() => (setOpened(!opened))}>
              <Icon name={'menu-ico'}/>
              {getMessage('catalog')}
            </div>
            {
              opened && state.sections.length ?
                <>
                  <div className={`catalog-menu-wrap ${opened ? 'catalog-menu-wrap--active' : ''}`}>
                    <div className="catalog-dropdown">
                      {
                        rootSections.length ?
                          rootSections.map(root_section => {
                            return <CatalogRootSection key={root_section.itemId}
                                                       section={root_section}
                                                       clientWidth={clientWidth}
                                                       sections={state.sections}
                                                       popularSections={state.popular}
                                                       popularLinks={state.links}
                                                       getMessage={getMessage}
                                                       onMouseOver={handleMouseOver}
                                                       active={activeRootSectionId !== null &&
                                                         Number(activeRootSectionId) === Number(root_section.itemId)}/>
                          }) :
                          <></>
                      }
                    </div>
                  </div>
                  <div className={'catalog-menu-backdrop'} onClick={() => (setOpened(false))}>
                    <ul className="circles"><li/><li/><li/><li/><li/><li/><li/><li/><li/><li/></ul>
                  </div>
                </> :
                <></>
            }
          </> :
          <></>
      }
    </>
  )
}

export default CatalogDropdownMenu