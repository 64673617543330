'use strict';

import React from 'react';
import HistoryList from '../HistoryList/HistoryList'

function HistoryBlock(props) {
    return (
      <div className="title-search-result show">
        <div className="bx_searche">
          <div className="search-result-main-info">
            <HistoryList
              items={props.items}
              title={props.messages['CT_BST_HISTORY_LIST_TITLE']}
              onItemClick={props.onQueryItemClick}
              onItemDelete={props.onDeleteHistoryQueryClick}
            />
          </div>
        </div>
      </div>
    );
}


export default HistoryBlock