'use strict'

import React from 'react'

function EmptyResult (props) {
  return (
    <div className="title-search-result show">
      <div className="bx_searche no_result">
        <div className="search-result-main-info">
          <span className="search_no_res_main_info">
              {props.messages['CT_BST_AJAX_SEARCH_CHANGE_QUERY']}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyResult