import React from 'react'
import './SearchInputBlock.css'
import { DebounceInput } from 'react-debounce-input'
import {eventEmitter} from "../../../common";

function SearchInputBlock (props) {

  eventEmitter.on('openSearch', () => {
    console.info('openSearch')
    onFocus()
  })
  function onFocus (e) {
    console.info('SearchInputBlock: onFocus')
    if (!props.showDropDown) props.setShowDropdown(true)
  }

  function onClickBackButton (e) {
    console.info('SearchInputBlock: onClickBackButton')
    props.setShowDropdown(false)
    props.handleClearQuery()
  }

  return (
    <div className={`search-input bx-input-group ${props.query.length ? 'has-val' : ''}`}>
        <span className="bx-input-group-btn">
          <button type="submit" className="search-submit" onClick={props.onSubmitQuery}>
              <svg>
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="/images/sprite.svg#search"></use>
              </svg>
              {/*{props.messages['CT_BST_SEARCH_BUTTON']}*/}
          </button>
        </span>
        <i className="icon-search"/>
        <DebounceInput
            value={props.query}
            inputRef={props.inputRef}
            enterKeyHint="search"
            minLength={2}
            debounceTimeout={500}
            type="text"
            autoComplete="off"
            placeholder={props.messages['CT_BST_SEARCH_LABEL']}
            className="input"
            id="h-search-input"
            onChange={props.onInput}
            onInput={props.onInputChange}
            onFocus={onFocus}
            onKeyUp={props.onKeyUp}/>
      <div className="search-input-holder">
      </div>
      {
          (props.query.length > 0 || window.innerWidth < 767) ?
          <span className="search-input-clear" onClick={props.onClearInput}>
              <svg>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="/images/sprite.svg#close"></use>
            </svg>
          </span> :
          <></>
      }
      <span className="search-back" onClick={onClickBackButton}/>
      <span className="search-mobile-icon js-search-btn">
          <i className="icon-search"/>
      </span>
    </div>
  )
}

export default SearchInputBlock