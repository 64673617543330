import React, { useEffect, useRef, useState } from 'react'
import './HeaderSearch.css'
import SearchInputBlock from './SearchInputBlock/SearchInputBlock'
import ResultDropdown from './ResultDropdown/ResultDropdown'
import EmptyResult from './EmptyResult/EmptyResult'
import HistoryBlock from './HistoryBlock/HistoryBlock'
import Cookies from 'js-cookie'
import { eventEmitter, esputnikTracker, lang, axiosConfig } from '../../common'
import axios from 'axios'

function HeaderSearch (props) {
  const [state, setState] = useState({
    start: true,
    query: '',
    productList: [],
    categoryList: [],
    historyList: [],
    potentialQueryList: [],
    total: 0,
    totalLink: props.params.detailPage,
    currentScreenVersion: (window.innerWidth <= 767) ? 'mobile' : 'desktop',
    messages: props.params.hasOwnProperty('messages') ? props.params.messages : {}
  })

  const [selectedQuery, setSelectedQuery] = useState(null)

  const [direct, setDirect] = useState(null)

  const [showDropdown, setShowDropdown] = useState(false)
  // для отображения поиска при изменении инпута
  const [showHistory, setShowHistory] = useState(true)
  // реф на input поиска
  const inputRef = useRef(null)
  // реф на основной блок поиска
  const ref = useRef(null)

  let potentialQuery = ''
  let sending = false

  let detailPage = props.params.detailPage
  let sessid = props.params.sessid
  let ajaxPath = props.params.ajaxPath
  let signedParamsString = props.params.signedParamsString
  let currencyID = props.params.currencyID

  const [isKeyboardOpen, setKeyboardOpen] = useState(false)

  // события
  useEffect(() => {
    initEvents()
  }, [])

  // стартовая загрузка истории поиска
  useEffect(() => {
    let data = {}

    sendRequest('requestHistoryQuery', data)
      .then(response => {
        if (response.hasOwnProperty('history'))
          setState({ ...state, historyList: response.history })
      })
      .catch(reason => console.log(reason))
  }, [])

  // поиск
  useEffect(() => {

    if (state.query.length <= 0) return
    let data = {
      'query': state.query,
      'fields': 'true',
      'currencyID': currencyID
    }

    sendRequest('searchItemQuery', data)
      .then(data => {
        // direct logic
        let total_link = ''
        let products = []
        let catogories = []
        let potential_query_List = []
        let count = 0

        if (data.hasOwnProperty('ITEMS')) {
          total_link = detailPage + '?q=' + state.query
          products = data['ITEMS']
          // esputnik search event
          //esputnikTracker.SearchRequest(state.query, Array.isArray(data.ITEMS) && data.ITEMS.length ? 1 : 0)
        }
        if (data.hasOwnProperty('SECTIONS')) catogories = data['SECTIONS']
        if (data.hasOwnProperty('POTENTIAL_QUERY_LIST')) potential_query_List = data['POTENTIAL_QUERY_LIST']
        if (data.hasOwnProperty('TOTAL')) count = data['TOTAL']

        setDirect(data.hasOwnProperty('DIRECT') && data.DIRECT !== null && data.DIRECT.length ?
          data.DIRECT :
          null)

        setState({
          ...state,
          totalLink: total_link,
          categoryList: catogories,
          productList: products,
          potentialQueryList: potential_query_List,
          total: count
        })
      })
      .catch(reason => console.log(reason))
  }, [state.query])

  // поиск
  useEffect(() => {
    if (state.query.length <= 0) return
    // сохраняем последнюю поисковую фразу
    Cookies.set('_ms_search_phase', state.query, { expires: 1 })
  }, [state.query])

  // событие клика / закрытие поиска
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  // событие клика вне блока
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && event.target.tagName.toLowerCase() !== 'body') {
      setShowDropdown(false)
    }

    const isSearchBlockClicked =
      event.target.closest('.header-links__item_mobile_search') !== null

    if (isSearchBlockClicked) {
      setShowDropdown(true)
    }
  }

  useEffect(() => {
    const handleFocus = () => {
      setKeyboardOpen(true)
    }

    const handleBlur = () => {
      setKeyboardOpen(false)
      if (inputRef.current) {
        // Открываем клавиатуру и устанавливаем фокус на элемент input
        //inputRef.current.focus();
      }
    }

    document.addEventListener('focus', handleFocus, true)
    document.addEventListener('blur', handleBlur, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
      document.removeEventListener('blur', handleBlur, true)
    }
  }, [])

  // Функция для вставки элемента после указанного элемента
  const insertAfter = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }

  useEffect(() => {
    const handleResize = () => {
      let headPanelSearch = document.querySelector('.header__bottom .search')
      let headPanel = document.querySelector('.header__bottom')
      let searchWrapper = document.querySelector('.search-wrapper')
      let mainMenu = document.querySelector('.main-menu')
      let headerInner = document.querySelector('.header__inner')

      if (window.innerWidth < 1024) {
        insertAfter(searchWrapper, headerInner)
        searchWrapper.style.display = 'none'
      } else if (window.innerWidth > 1023 && !searchWrapper.nextSibling.isEqualNode(mainMenu)) {
        // Возвращаем обратно, если размер экрана больше 1023px
        insertAfter(searchWrapper, mainMenu)
        searchWrapper.style.display = 'block'
      }
    }

    // Вызываем функцию обработки при монтировании компонента
    handleResize()

    // Подписываемся на событие изменения размера окна (кроме android устройств)
    if (!navigator.userAgent.match(/Android/i)) {
      window.addEventListener('resize', handleResize)
    }

    // Отписываемся от события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    //debugger
    //console.info(`showDropdown: ${showDropdown}`)
    let bg = document.querySelector('.search-bg')
    let menu = document.querySelector('.side-menu')
    let head_panel_search = document.querySelector('.header .search')
    let head_panel = document.querySelector('.header__bottom')
    let drop_down_containers = document.querySelectorAll('.dropdown-main-container')
    let search_block = document.querySelector('.search')
    let search_holder = document.querySelector('.search-holder')
    let searchinput = document.querySelector('#h-search-input')
    let searchInputClear = document.querySelector('.search-input-clear')
    let searchWrapper = document.querySelector('.search-wrapper')

    //console.log(showDropdown)
    if (showDropdown) { // показ выпадающего меню
      if (menu) menu.style.zIndex = '1'

      if (search_block instanceof HTMLElement) search_block.classList.add('opened')
      if (head_panel instanceof HTMLElement) head_panel.classList.add('search-opened')
      searchWrapper.style.display = 'block'

      drop_down_containers.forEach(drop_down_container => {
        if (drop_down_container.classList.contains('opened'))
          drop_down_container.classList.remove('opened')
      })

      if (search_holder && search_block) {
        //if (window.innerWidth < 1024) search_holder.style.left = `calc(${search_block.offsetLeft}px - 25px)`
        //else search_holder.style.left = `calc(${search_block.offsetLeft}px)`
      }

      if (window.innerWidth <= 767) {
        let iframe = document.querySelector('iframe[src="https://widget-omnibutton.livetex.ru/widget.html"]')
        if (iframe instanceof HTMLElement)
          iframe.style.zIndex = '10'

        if (searchInputClear) searchInputClear.style.display = 'block'
      }
      // мобайл часть
      if (window.innerWidth < 1024) {
        if (bg) bg.classList.add('active-bg')
        eventEmitter.emit('closeDesktopMenuDropdown')
        let mobile_menu = document.querySelector('.burger-menu')

        if (mobile_menu instanceof HTMLElement && mobile_menu.classList.contains('opened')) mobile_menu.click()
        document.body.classList.add('side-open')
        inputRef.current.focus()
      }
    } else { // скрытие выпадающего меню
      if (bg) bg.classList.remove('active-bg')
      if (search_block instanceof HTMLElement) search_block.classList.remove('opened')
      if (head_panel instanceof HTMLElement) head_panel.classList.remove('search-opened')
      // мобайл часть
      if (window.innerWidth < 1024) {
        document.body.classList.remove('side-open')
        searchWrapper.style.display = 'none'
      }
      if (window.innerWidth < 767) {
        if (searchInputClear) searchInputClear.style.display = 'none'
      }

    }
  }, [showDropdown])

  function initEvents () {
    // клик по иконке поиска в мобайл
    let mobile_search_btn = document.querySelector('.js-search-btn')
    if (mobile_search_btn instanceof HTMLElement) {
      mobile_search_btn.addEventListener('click', event => {

      })
    }

    // скролл страницы
    //document.addEventListener('scroll', onScroll)
  }

  function onScroll (event) {
    //console.info(`HeaderSearch: onScroll`)
    let searchBlock = document.querySelector('.search')
    let searchHolder = searchBlock.querySelector('.search-holder')
    let rightBlock = document.querySelector('.header-top')
    let offsetRight = rightBlock.offsetWidth
    let header = document.getElementById('header')

    if (header.classList.contains('fixed')) searchHolder.style.right = `-${offsetRight}px`
    else searchHolder.style.right = `0`
  }

  function onKeyUp (e) {
    if (e.code === 'Enter' && state.query.length) onSubmitQuery()
  }

  // ввод поискового запроса
  function onQueryChange (e) {
    if (sending) return
    setState({ ...state, 'query': e.target.value })
    onQueryInput(e)
  }

  function onQueryInput (e) {
    if (sending) return
    if (e.target.value.length > 0) setShowHistory(false)
    else setShowHistory(true)
  }

  function onSubmitQuery () {
    if (sending) return;
    if (direct !== null && direct.length) window.location.href = lang === 'ua' ? `/${lang}${direct}` : direct
    else {
      if (state.total) {
        location.href = state.totalLink
      } else location.href = `${detailPage}?q=${state.query}`
    }
  }

  // выполнение поиска по клику на элемент potential query list
  function onSubmitPotentialQuery (selected, query_string) {
    if (selected !== null && selected.length) window.location.href = lang === 'ua' ? `/${lang}${selected}` : selected
    else {
      location.href = `${detailPage}?q=${query_string}`
    }
  }

  // очистка значения query при клике на search-back
  function handleClearQuery () {
    setState({ ...state, query: '' })
    setShowHistory(true)
  }

  /**
   * удаление элемента истории поиска
   * @param query
   */
  function onDeleteHistoryQueryClick (query) {
    sendRequest('deleteQueryHistoryItem', { 'query': query })
      .then(data => {
        if (data.hasOwnProperty('history') && Array.isArray(data.history))
          setState({ ...state, 'historyList': data.history })
      })
      .catch(reason => console.log(reason))
  }

  /**
   * клик по элементу истории поиска
   * @param query_string
   */
  function onQueryItemClick (query_string) {
    setState({ ...state, query: query_string })
  }

  // клик по элементу potential query list
  function onPotentialQueryClick (query_string) {
    setState({ ...state, query: query_string })

    sendRequest('searchItemQuery', {
      'query': query_string,
      'fields': 'true',
      'currencyID': currencyID
    })
      .then(data => {
        onSubmitPotentialQuery(data.hasOwnProperty('DIRECT') && data.DIRECT !== null && data.DIRECT.length ?
          data.DIRECT :
          null, query_string)
      })
      .catch(reason => console.log(reason))
  }

  /**
   * очистка строки поиска
   * @param event
   */
  function onClearInput (event) {
    setState({ ...state, query: '' })
    inputRef.current.focus()
    setShowHistory(true)
    setShowDropdown(false)
  }

  /**
   * Sends a request using axios to the specified URL with the provided request data.
   *
   * @param {object} request_data - The request data to send.
   * @returns {Promise<object>} A Promise that resolves to the response data or rejects with an error.
   */
  function sendRequest (action, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()

      //formData.append('signedParamsString', signedParamsString)

      for (let i in data) if (data.hasOwnProperty(i)) formData.append(i, data[i])

      sending = true

      axios.post(`${ajaxPath}${action}/`, formData, axiosConfig)
        .then(response => {
          console.info(response.data)
          sending = false
          resolve(response.data)
        })
        .catch(error => {
          sending = false
          reject(error)
        })
    })
  }

  return (
    <div className="search-holder" ref={ref}>
      <SearchInputBlock inputRef={inputRef}
                        messages={state.messages}
                        setShowDropdown={setShowDropdown}
                        showDropDown={showDropdown}
                        query={state.query}
                        onInput={onQueryChange}
                        onInputChange={onQueryInput}
                        onKeyUp={onKeyUp}
                        onClearInput={onClearInput}
                        handleClearQuery={handleClearQuery}
                        onSubmitQuery={() => {
                          if (selectedQuery) {
                            onPotentialQueryClick(selectedQuery)
                          } else {
                            onSubmitQuery()
                          }
                        }}/>
      {
        showDropdown ?
          state.productList.length > 0 ?
            <ResultDropdown
              productList={state.productList}
              categoryList={state.categoryList}
              historyList={state.historyList}
              potentialQueryList={state.potentialQueryList}
              total={state.total}
              historyToggle={showHistory}
              totalLink={state.totalLink}
              messages={state.messages}
              onPotentialQueryClick={onPotentialQueryClick}
              setSelectedQuery={setSelectedQuery}
              onDeleteHistoryQueryClick={onDeleteHistoryQueryClick}/> :
            state.historyList.length > 0 && showHistory ?
              <HistoryBlock items={state.historyList}
                            messages={state.messages}
                            onQueryItemClick={onQueryItemClick}
                            onDeleteHistoryQueryClick={onDeleteHistoryQueryClick}/> :
              <EmptyResult messages={state.messages}/> :
          <></>
      }
    </div>
  )
}

export default HeaderSearch