import { eventEmitter, lang, sessid } from '../../common'
import axios from 'axios'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import qs from 'qs'

class MultisearchTracking {
  apiUrl = 'https://track.multisearch.io/'
  trackerId = '4jip6d5syb0c'
  config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

  constructor () {
    this.initEvents()
  }

  initEvents () {
    eventEmitter.on('MSSearch', eventData => (this.onSearch(eventData)))
    eventEmitter.on('MSAddToCart', eventData => (this.onAddToCart(eventData)))
    eventEmitter.on('MSClick', eventData => (this.onClick(eventData)))
    eventEmitter.on('MSAddClickHistory', eventData => (this.onAddClickHistory(eventData)))
  }

  getUid () {
    let uuid = Cookies.get('_ms_uuid')
    if (!uuid) {
      Cookies.set('_ms_uuid', uuidv4(), 365 * 10)
      uuid = Cookies.get('_ms_uuid')
    }

    return uuid
  }

  getClickHistory () {
    return Cookies.get('_ms_click_oid') || null
  }

  setClickHistory (oid) {
    Cookies.set('_ms_click_oid', oid, { expires: 1 })
  }

  sendTracking (data) {
    return axios.post(this.apiUrl, qs.stringify(data), this.config)
  }

  isWasSearch () {
    return Cookies.get('_ms_is_search') === 'true' ? 1 : 0
  }

  getLastSearchPhase () {
    return Cookies.get('_ms_search_phase') || ''
  }

  onSearch (eventData) {
    console.info('MSSearch', eventData)
    // сохраняем признак поиска is_search в куку
    Cookies.set('_ms_is_search', 'true', { expires: 1 })
    // отправляем событие поиск в трекинг
    let trackingData = {
      id: this.trackerId,
      q: this.getLastSearchPhase(),
      uid: this.getUid(),
      e: 'search',
      s: this.isWasSearch()
    }
    this.sendTracking(trackingData)
      .then(response => {
        console.info('MSSearch sended')
      })
      .catch(reason => {
        console.log(reason)
      })
  }

  onClick (eventData) {
    console.info('MSClick', eventData)
    // проверка истории клика
    let last_prod_id = this.getClickHistory()
    if (last_prod_id === null || last_prod_id !== eventData.productId) return
    // отправляется событие click
    let trackingData = {
      id: this.trackerId,
      q: this.getLastSearchPhase(),
      uid: this.getUid(),
      oid: eventData.productId,
      e: 'click',
      s: this.isWasSearch()
    }
    this.sendTracking(trackingData)
      .then(response => {
        console.info('MSClick sended')
      })
      .catch(reason => {
        console.log(reason)
      })
  }

  onAddClickHistory (eventData) {
    // сохраняется товар по которому был клик
    this.setClickHistory(eventData.productId)
  }

  onAddToCart (eventData) {
    console.info('MSAddToCart', eventData)

    let last_prod_id = this.getClickHistory()
    let trackingData = {
      id: this.trackerId,
      uid: this.getUid(),
      oid: eventData.productId,
      e: 'add2cart',
      s: this.isWasSearch()
    }

    let is_search_page = window.location.pathname === '/search/' || window.location.pathname === '/ua/search/'

    if ((last_prod_id !== null && Number(last_prod_id) === Number(eventData.productId)) || is_search_page)
      trackingData.q = this.getLastSearchPhase()

    // отправляется событие addToCart
    this.sendTracking(trackingData)
      .then(response => {
        console.info('MSAddToCart sended')
      })
      .catch(reason => {
        console.log(reason)
      })
  }
}

export default MultisearchTracking